import React from "react";
import { Button, message } from "antd";
import axios from "axios";

const Checkout = ({ plan, userId, uid }) => {
  const redirectToCheckout = () => {
    message.info("Redirecting to payment page...");

    const data = JSON.stringify({
      clientReferenceId: userId,
      price: plan,
      uid,
    });

    const config = {
      method: "post",
      url: `${process.env.GATSBY_API_BASE_URL}/create_checkout_link`,
      headers: {
        "Content-Type": "application/json",
      },
      data,
    };

    axios(config)
      .then(function (response) {
        const { url } = response.data;
        window.open(url);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      <Button
        width="100%"
        type="primary"
        style={{
          fontSize: 14,
        }}
        onClick={() => redirectToCheckout()}
      >
        Subscribe
      </Button>
    </>
  );
};

export default Checkout;
